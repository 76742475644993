import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildEventUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { CLIENT_SIDE_PAGINATION_LIMIT, DEFAULT_SORT_OPTION } from 'state-domains/constants';

import { sendRetrieveRequest } from '../../../utils';
import { EventsObject } from '../types';

export const loadEvents = (
    filters: FilterObject[],
    limit = CLIENT_SIDE_PAGINATION_LIMIT,
    offset = 0,
    sort = DEFAULT_SORT_OPTION,
): Observable<{ count: number; Event: EventsObject[] }> => {
    const obs = sendRetrieveRequest(buildEventUrlWithFilters(filters, limit, offset, sort));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<{ count: number; Event: EventsObject[] }>) => {
            return observableOf(convertToCamel<{ count: number; Event: EventsObject[] }>(response));
        }),
    );
};
